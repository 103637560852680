import {
  Helmet,
  Theme,
  PreStyledComponents,
  CategoryLink,
  Banner,
  Box,
  Container,
  Heading,
  Row,
  routesObject
} from "@life-without-barriers/react-components"
import {
  helpers,
  stateHelpers,
  sendEvent,
  pageUrls
} from "@life-without-barriers/utilities"
import { Site } from "@life-without-barriers/gatsby-common"
import React, { ChangeEvent } from "react"
import { graphql, navigate } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import sortby from "lodash.sortby"

import Layout from "../../components/disability/Layout"
import ContactUsSection from "../../components/disability/ContactUsSection"
import { DisabilityForm } from "@life-without-barriers/shared-contact-form"
import SilListingCard from "../../components/disability/SilListingCard"
import { SdaCategory } from "../../contentTypes"

const { createSilStateIndexUrl } = pageUrls

const { truncate } = helpers
const { getFullState } = stateHelpers

const { GridWrapper } = PreStyledComponents

const { disabilityTheme } = Theme

function eventTracking(
  eventCategory: string,
  eventAction: "Click",
  eventLabel: string
) {
  sendEvent(eventCategory, eventAction, eventLabel)
}

interface SilIndexListing {
  title: string
  listingDescription?: string
  slug: string
  state: string
  accessible: string
  introduction: {
    text: string
  }
  sdaCategory: SdaCategory
  date: string
  featureImage: {
    gatsbyImageData: IGatsbyImageData
    file: {
      url: string
      fileName: string
      contentType: string
    }
    title: string
  }
}

interface Props {
  location: { pathname: string }
  pageContext: {
    state: string
    allStates: string[]
  }
  data: {
    site: Site
    metaImage: IGatsbyImageData
    silVacancies: {
      edges: [
        {
          node: SilIndexListing
        }
      ]
    }
  }
}

const bannerBreadcrumbs = [
  routesObject.disabilityHome,
  {
    to: "/disability/services",
    text: "Disability services",
    title: "Return navigation"
  },
  {
    to: "/disability/services/supported-independent-living",
    text: "Supported Independent Living",
    title: "Return navigation"
  }
]

const StateCategoryLink = (state: string, active: boolean) => {
  let StateURL
  if (state === "All") {
    StateURL = createSilStateIndexUrl()
  } else {
    StateURL = createSilStateIndexUrl(state)
  }

  return (
    <CategoryLink
      active={active}
      activeColor={disabilityTheme.medium}
      theme={disabilityTheme}
      title={state}
      key={`sil-${state}`}
      to={StateURL}
      ga={{
        eventCategory: "SIL Vacancies",
        eventLabel: state
      }}
    />
  )
}

const VacanciesTemplate = ({
  location,
  pageContext: { state, allStates },
  data: {
    site: { siteMetadata },
    metaImage,
    silVacancies
  }
}: Props) => {
  const vacancies = silVacancies.edges.map(({ node }) => ({
    ...node,
    href: `/disability/services/supported-independent-living/vacancies/${node.slug}`,
    title: truncate(node.title, 65),
    copy: node.listingDescription
      ? node.listingDescription
      : truncate(node.introduction.text, 110),
    image: node.featureImage.gatsbyImageData
  }))

  const sortedVacancies = sortby(vacancies, ["title"])

  const onCategoryChange = (changeEvent: ChangeEvent<HTMLSelectElement>) => {
    eventTracking("SIL Vacancies", "Click", changeEvent.target.value)
    if (changeEvent.target.value === "All") {
      navigate(createSilStateIndexUrl()).then(
        () => {
          return
        },
        () => {
          return
        }
      )
    } else {
      navigate(createSilStateIndexUrl(changeEvent.target.value)).then(
        () => {
          return
        },
        () => {
          return
        }
      )
    }
  }

  const MobileCategorySelect = () => (
    <select className="w-100" value={state} onChange={onCategoryChange}>
      {[...allStates].map((c) => (
        <option key={`cat-${c}`} value={c}>
          {getFullState(c) || "All"}
        </option>
      ))}
    </select>
  )

  const Vacancies = () => (
    <>
      <Heading size={2} topMargin="standard" sectionHeading>
        Current Vacancies
      </Heading>
      <GridWrapper className="flex-ns flex-wrap-ns flex-start-ns">
        {sortedVacancies.map((v, index) => (
          // Only fade in on the first page because otherwise we get flicker when loading more.
          <SilListingCard {...v} key={index} />
        ))}
      </GridWrapper>
    </>
  )

  const ContactVacancies = () => (
    <ContactUsSection
      formId="sil-vacancies-index"
      formKind="disability-home-living"
      title="Didn't find the right home for you?"
      subtitle="Get in touch and let us know where you’re hoping to find a home and what your needs are, as we may be able to help."
      phoneNumber={contactDetails.phoneNumber}
      emailAddress={contactDetails.emailAddress}
    />
  )

  const ContactNoVacancies = () => (
    <ContactUsSection
      formId="sil-vacancies-index"
      formKind="disability-home-living"
      title="Looking for accomodation options? We can help with:"
      subtitle="Get in touch and let us know where you’re hoping to find a home, what your needs are, and how we can support you."
      phoneNumber={contactDetails.phoneNumber}
      emailAddress={contactDetails.emailAddress}
      noVacancy
    />
  )

  const title = state
    ? `Supported Independent Living vacancies - ${state}`
    : `Supported Independent Living vacancies`

  const contactDetails = {
    phoneNumber: "1800610699",
    emailAddress: "disability.enquiries@lwb.org.au"
  }

  return (
    <div>
      <Helmet
        title={`${title} | ${siteMetadata.title}`}
        description="View our current Supported Independent Living vacancies."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={metaImage}
      />
      <Layout
        fixedCTAPhoneNumber={contactDetails.phoneNumber}
        fixedCTAContact={
          <DisabilityForm
            formId="sil-vacancies-index-fixed-cta"
            formKind="disability-home-living"
          />
        }
      >
        <Banner
          title="Supported Independent Living vacancies"
          subTitle="View our current Supported Independent Living (SIL) vacancies
                across Australia. If you can’t find a home that suits your
                needs, get in touch with us."
          breadcrumbs={bannerBreadcrumbs}
        />
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100">
              <Heading size={2} className="tc-ns mb3" sectionHeading>
                Filter by state
              </Heading>
              <div className="tc dn db-ns">
                {[...allStates].map((c) =>
                  StateCategoryLink(c, c === (state || "All"))
                )}
              </div>
              <div className="db dn-ns">
                <MobileCategorySelect />
              </div>
              {vacancies.length ? <Vacancies /> : ""}
            </Box>
          </Row>
        </Container>
        {vacancies.length ? <ContactVacancies /> : <ContactNoVacancies />}
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($states: [String]) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(relativePath: { regex: "/OG-image-sil.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 90)
      }
    }
    silVacancies: allContentfulSilListing(
      filter: { state: { in: $states } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          listingDescription
          state
          accessible
          sdaCategory
          date: updatedAt(formatString: "D MMMM YYYY")
          introduction {
            text: introduction
          }
          featureImage {
            ...ForcedAspectRatioRemoteImageAssetQuery
          }
        }
      }
    }
  }
`

export default VacanciesTemplate
